import { useEffect, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom"
import axiosInstance from "../common/axios";
import { getErrors, toastDefault, hasData } from "../utils/handler.utils";
import { toast } from 'react-toastify';
import useGet from '../hooks/useGet';
import Loader from './Loader/Loader';


function Settings(props) {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const tenTakaOffer = useRef(null);
    const [refresh, setRefresh] = useState(1);
    const [data, loadingData, error] = useGet(`admin/settings`, null, refresh)

    const createPaymentMethodHandler = (e) => {
        e.preventDefault()

            setLoading(true)
            axiosInstance.post('/admin/settings', {
                ten_taka_offer: tenTakaOffer.current.checked ? 1 : 0,
            }).then(res => {
                toast.success('Setting updated successfully', toastDefault)
                setTimeout(() => {
                    history.push('/')
                }, 1500);
            }).catch(err => {
                toast.error(getErrors(err, false, true), toastDefault)
                setLoading(false)
            })
    
    }

    return (
        <section className="relative container_admin" >
            <div className="bg-white overflow-hidden rounded">
                <div className="px-6 py-3 border-b border-gray-200">
                    <h3 className="text-lg font-bold text-black">
                        Settings
                    </h3>
                </div>
                <div className="md:px-6 grid grid-cols-1 md:grid-cols-[1000%,auto] gap-6 my-10 " >
                    <div className="rounded relative overflow-hidden">


                    {loadingData && <Loader absolute />}
                        {loading && <Loader absolute />}
                        {
                            hasData(data, loading, error) && (
                                <form onSubmit={createPaymentMethodHandler} >
                                    <div>
                            

                                        <div className="cursor-pointer" >
                                            <label htmlFor="isactive" className="select-none cursor-pointer">10 Taka Offer</label>
                                            <input ref={tenTakaOffer} defaultChecked={data?.ten_taka_offer === 'on' ? true : false} id="offer" type="checkbox" className="mr-2" />
                                        </div>

                                        <div className="mt-4">
                                            <button type="submit" disabled={loading || loadingData} className="cstm_btn w-full block"> Submit </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }

                    </div>
          
                </div>
            </div>
        </section>
    )
}

export default withRouter(Settings)
